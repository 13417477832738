<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="item.isLoading"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :dependentLinks="dependentLinks"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave('Accounts')"
    @cancel="onCancel('Accounts')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('editableList');
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'AccountEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      controllerName: 'Accounts',
      pageHeader: 'Редактирование аккаунта',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Аккаунты',
          route: { name: 'Accounts' },
        },
        {
          text: 'Редактирование аккаунта',
        },
      ],
      form: {},
    };
  },

  computed: {
    ...mapState(['item']),

    dependentLinks() {
      return [
        {
          text: 'Связанные клиенты',
          route: {
            name: 'CustomersForAccount',
            params: {
              id: this.id,
            },
          },
        },
      ];
    },

    fields() {
      return [
        {
          tab: 'Основные',
          form: [
            {
              type: 'text',
              key: 'fio',
              label: 'ФИО',
            },
            {
              type: 'text',
              key: 'login',
              label: 'Логин (телефон)',
            },
            {
              type: 'text',
              key: 'phone',
              label: 'Дополнительные телефоны (заполнять через ;)',
            },
            {
              type: 'text',
              key: 'password',
              label: 'Пароль',
            },
            {
              type: 'text',
              key: 'mail',
              label: 'Email',
            },
            {
              type: 'date',
              key: 'created',
              label: 'Создан',
            },
            {
              type: 'text',
              key: 'position',
              label: 'Должность',
            },
            {
              type: 'text',
              key: 'comment',
              label: 'Комментарий',
            },
            {
              type: 'checkbox',
              key: 'isUseChatBot',
              label: 'Использовать чат-бота WhatsApp',
            },
          ],
        },
        {
          tab: 'Параметры для сервиса UIS',
          form: [
            {
              type: 'text',
              key: 'uisToken',
              label: 'Ключ (генерируется в личном кабинете UIS)',
            },
            {
              type: 'text',
              key: 'uisVirtualPhone',
              label: 'Виртуальный номер, арендуемый клиентом',
            },
            // {
            //   type: 'text',
            //   key: 'uisOperatorPhone',
            //   label: 'Телефон оператора',
            // },
          ],
        },
      ];
    },
  },

  created() {
    this.getItem({ name: this.controllerName, id: this.id }).then(() => {
      this.form = this.lodash.cloneDeep(this.item.data);
    });
  },

  methods: {
    ...mapActions(['getItem', 'updateItem']),
  },
};
</script>
